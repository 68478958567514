import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../Theme2DashboardTabs/ScrollToTop";
import { useSelector } from "react-redux";

function ThirdFooter() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <div>
      <section
        id="get-quote-section"
        class="get-quote-section bg-theme pad-none pad-top-md-none pad-bottom-md-none relative o-visible index-1"
      >
        <div className="container">
          <div className="b-radius-8 pad-top-50 pad-bottom-45 pe-5 pe-lg-0 ps-lg-0 ps-5">
            <div className="row get-quote-1">
              <div className="col-lg-2 pe-lg-0 ps-lg-5 pb-4 pb-lg-0 align-self-center">
                <div className="quote-icon">
                  <img
                    src="/assets/images/icons/phone-call.png"
                    className="img-fluid thumb ms-0 ms-lg-auto"
                    width="100"
                    height="100"
                    alt="events-img"
                  />
                </div>
              </div>
              <div className="col-lg-6 px-3 px-lg-4 pe-lg-5 br-right">
                <div className="title-wrap mb-0">
                  <div className="section-title mb-0">
                    <h3 className="title mb-3 mb-lg-1 acheivement-bottom-text">
                      Mist is proud to serve you 24/7. Just Call Us when you
                      need
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ps-lg-0 pe-lg-3 justify-content-left justify-content-lg-center d-flex align-self-center">
                <div className="contact-info">
                  <h3 className="acheivement-bottom-text">
                    Call Us Anytime:{" "}
                    <a href={`tel:${sitedata?.site_contact}`}>
                      {sitedata?.site_contact}
                    </a>
                  </h3>
                  <div className="get-quote-3 button">
                    <Link
                      className="btn btn-border"
                      target="_blank"
                      title="Download Now"
                      to={"/appointment"}
                    >
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer" class="footer bg-grey2 footer-1">
        <div
          class="footer-widgets section-bg-img relative o-hide bg-fixed"
          style={{
            backgroundImage: `url(/assets/images/footer-bg.png)`,
          }}
        >
          <span class="black-overlay"></span>
          <div class="footer-middle-wrap pad-tb-90">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h2 class="theme-color pad-bottom-20 tfooter-head-color">
                    Contact Us Today
                  </h2>
                  <h3 class="typo-white">
                    <a href={`tel:${sitedata?.site_contact}`}>
                      {sitedata?.site_contact}
                    </a>
                  </h3>
                  <h3 class="typo-white">
                    <a href={`mailto:${sitedata?.site_email}`}>
                      {sitedata?.site_email}
                    </a>
                  </h3>
                </div>
                <div class="social-icons ft-social-lg br-style col-md-12 text-center pad-top-20">
                  {sitedata?.site_extra?.facebook_link && (
                    <a
                      href={sitedata?.site_extra?.facebook_link}
                      className="social-fb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  )}
                  {sitedata?.site_extra?.twitter_link && (
                    <a
                      href={sitedata?.site_extra?.twitter_link}
                      className="social-twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-twitter"></span>
                    </a>
                  )}
                  {sitedata?.site_extra?.instagram_link && (
                    <a
                      href={sitedata?.site_extra?.instagram_link}
                      className="social-instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  )}
                  {sitedata?.site_extra?.linkedin_link && (
                    <a
                      href={sitedata?.site_extra?.linkedin_link}
                      className="social-instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-linkedin"></span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-copyright bg-white">
          <div class="footer-bottom-wrap pad-tb-20">
            <div class="container">
              <div class="row">
                <div class="col-md-12 copyright-section">
                  <ul class="footer-bottom-items pull-left">
                    <li class="nav-item">
                      <div class="nav-item-inner copyright">
                        Copyrights © 2023 <a>ClinTa</a>. Powered by
                        <a
                          href="https://dright.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          D-Right Technologies
                        </a>
                      </div>
                    </li>
                    <ul class="footer-bottom-items footer-menu">
                      <li class="nav-item">
                        <a href="#">Terms of Use</a>
                      </li>
                      <li class="nav-item">
                        <a href="#">Privacy Policy</a>
                      </li>
                    </ul>
                  </ul>
                  <ScrollToTop />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* {sitedata?.whatsapp_chat && (
        <FloatingWhatsApp
          phoneNumber={sitedata?.whatsapp_chat}
          message="Hello!"
          accountName={sitedata?.site_title}
           statusMessage="For Appointments Only"
          // avatar="/assets/img/whatsapp-icon.webp"
          avatar={sitedata?.site_logo}
        />
      )} */}
    </div>
  );
}

export default ThirdFooter;
