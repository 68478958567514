import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";

function CeoMessage() {
  const [hostpitalAbout, setHostpitalAbout] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [truncateLength, setTruncateLength] = useState(770);

  useEffect(() => {
    getHostpitalAboutData();
  }, []);

  const getHostpitalAboutData = async () => {
    const response = await SendPostRequest("content/about");
    setHostpitalAbout(response?.data);
  };
  const createMarkup = (html) => {
    return { __html: html };
  };
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.slice(0, maxLength) + "...";
    }
    return text;
  };

  const truncatedMessage = truncateText(
    hostpitalAbout?.message,
    truncateLength
  );

  return (
    <>
      {hostpitalAbout?.message && hostpitalAbout?.message_image && (
        <section
          id="section-about"
          className="section-about pb-0 pt-0"
        >
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 pe-lg-5" data-animation="fadeInLeft">
                <div className="about-wrap relative">
                  <div className="about-wrap-inner">
                    <div className="about-wrap-details">
                      <div className="text-center">
                        <div className="about-img">
                          <img
                            // src="/assets/images/about-us/about-5.jpg"
                            src={hostpitalAbout?.message_image}
                            className="tr-radius"
                            alt="about-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
              <div className="col-lg-6 py-5" data-animation="fadeInRight">
                <div className="title-wrap margin-bottom-20">
                  <div className="section-title">
                    <div className="section-title margin-bottom-40">
                      <h2 className="title text-uppercase mb-0">
                        CEO <span className="theme-color">Message</span>
                      </h2>
                      <span className="section-border-bottom"></span>
                    </div>
                  </div>
                  <div className="pad-top-5">
                    <div>
                      <span
                        dangerouslySetInnerHTML={createMarkup(
                          expanded ? hostpitalAbout?.message : truncatedMessage
                        )}
                      />
                      {/* <button
                        className="home_ceomessage_btn d-inline"
                        onClick={() => setExpanded(!expanded)}
                      >
                        {expanded ? "Read Less" : "Read More"}
                      </button> */}
                    </div>
                  </div>
                </div>
                <Link
                  onClick={() => setExpanded(!expanded)}
                  className="btn btn-default"
                >
                  {expanded ? "Read Less" : "Read More"}
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default CeoMessage;
