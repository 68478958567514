import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SendPostRequest } from "../../http";
import { Link, useNavigate } from "react-router-dom";
import { setSelectedDoctor } from "../../store/global/actions";
import { capitalize } from "@mui/material/utils";
import createSlug from "../../utils/CreateSlug";
import Spinner from "../Spinner";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function DoctorListing() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [doctorsData, setDoctorsData] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isMobile, setIsMobile] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

 
  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Doctors - ClinTa";
    window.scrollTo(0, 0);
    getDoctorsData();

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getDoctorsData = async () => {
    try {
      const response = await SendPostRequest("doctors/list");
      setDoctorsData(response?.data);
      setFilteredDoctors(response?.data);
      const uniqueDepartments = Array.from(
        new Set(response?.data.map((doctor) => doctor?.speciality || "All"))
      );
      setSpeciality(uniqueDepartments.sort());
    } catch (error) {
      console.error("Error fetching doctors data:", error);
    } finally {
      setLoading(false);
    }
  };

  const setSelectedDoctorData = (data) => {
    dispatch(setSelectedDoctor(data));
  };

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
    setFilteredDoctors(() => {
      if (category === "All") {
        return doctorsData;
      } else if (category === "e_health") {
        return doctorsData.filter((doctor) => doctor?.telemedicine_id !== 0);
      } else {
        return doctorsData.filter((doctor) => doctor?.speciality === category);
      }
    });
  };

  return (
    <div className="page-wrapper-inner">
      <div className="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row text-left">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    <h1 className="page-title mb-0">Doctors</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          id="section-products"
          className="page-section bg-grey border-tb"
          data-animation="fadeInDown"
        >
          <div class="container">
            <div class="offset-md-2 col-md-8">
              <div class="title-wrap text-center">
                <div class="section-title margin-bottom-60">
                  <h2 class="section-title mb-0 text-uppercase">
                    MEET OUR <span class="theme-color">SPECIALISTS</span>
                  </h2>
                  <span class="section-border-bottom center"></span>
                </div>
              </div>
            </div>
             {/* Add Dropdown for Mobile */}
             {isMobile && (
                    <div className="mb-3 doctor-search-mobile px-1">
                      <label>Search by Speciality</label>
                      <select
                        className="form-control doctor-search-select mb-4"
                        value={selectedCategory}
                        onChange={(e) => handleFilterClick(e.target.value)}
                      >
                        {/* <option value="All">All</option> */}
                        {speciality.map((category, index) => (
                          <option key={index} value={category}>
                            {category}
                          </option>
                        ))}
                        <option value="e_health">E-Health</option>
                      </select>
                    </div>
                  )}
            <div class="row shop style-2 popup-gallery">
              {!isMobile && filteredDoctors.length > 0 && (
                <div className="sidebar col-lg-3">
                  <div class="widget category-widget">
                    <div class="widget-title">
                      <h3 class="title">Speciality</h3>
                    </div>
                    <ul class="category-list">
                      {speciality?.map((category, index) => (
                        <li
                          key={index}
                          className={`cat-item ${
                            selectedCategory === category ? "active" : ""
                          }`}
                          onClick={() => handleFilterClick(category)}
                        >
                          <a> {category}</a>
                        </li>
                      ))}
                      <li
                        className={`cat-item ${
                          selectedCategory === "e_health" ? "active" : ""
                        }`}
                        onClick={() => handleFilterClick("e_health")}
                      >
                        <a> E-Health</a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <div
                className={
                  filteredDoctors && filteredDoctors.length > 0
                    ? "col-lg-9"
                    : "col-lg-12"
                }
              >
                <div className="row">
                  {loading ? (
                    <div className="col-12 text-center">
                      <Spinner />
                    </div>
                  ) : filteredDoctors && filteredDoctors.length > 0 ? (
                    filteredDoctors?.map((data, index) => (
                      <div key={index} className="col-sm-12 col-md-4 mb-5">
                        <div className="product-item">
                          <div className="product-box-wrap">
                            <div className="product-img">
                              <div
                                style={{
                                  backgroundImage: `url(${data?.profile})`,
                                  backgroundPosition: "top center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  height: "280px",
                                }}
                              ></div>
                              <a className="popup-img" title="Open Profile">
                                <i
                                  className="ti-plus text-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedDoctorData({
                                      fullName: data?.full_name,
                                      profilePic: data?.profile,
                                      department: data?.department,
                                    });
                                    navigate(
                                      `/doctor/${data?.uid}/${createSlug(
                                        data?.full_name
                                      )}`
                                    );
                                  }}
                                ></i>
                              </a>
                            </div>
                            <div className="product-details text-center mt-3">
                              <h5 className="mb-0">{data?.full_name}</h5>
                              <h5 className="theme-color">
                                {data?.department}
                              </h5>
                            </div>
                          </div>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedDoctorData({
                                fullName: data?.full_name,
                                profilePic: data?.profile,
                                department: data?.department,
                              });
                              navigate(
                                `/doctor/${data?.uid}/${createSlug(
                                  data?.full_name
                                )}`
                              );
                            }}
                            className="btn btn-default btn-block"
                          >
                            Book An Appointment
                          </a>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12 text-center">
                      <h4>No Data Found</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterWrapper />
      </div>
    </div>
  );
}

export default DoctorListing;
