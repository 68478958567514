import React, { useEffect, useState } from "react";
import HeaderWrapper from "./Header/HeaderWrapper";
import FooterWrapper from "./Footer/FooterWrapper";
import { SendPostRequest } from "../http";
import { useParams } from "react-router-dom";
import { capitalize } from "@mui/material";
import { useSelector } from "react-redux";

function GalleryDetail() {
  const [galleryDetail, setGalleryDetail] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);
  const { id } = useParams();

  const fetchGalleryDetail = async () => {
    try {
      const response = await SendPostRequest("gallery/details", {
        images_uid: id,
      });
      setGalleryDetail(response?.data || []);
    } catch (error) {
      console.error("Error fetching gallery details:", error);
    }
  };

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Gallery Detail - ClinTa";
    window.scrollTo(0, 0);
    fetchGalleryDetail();
  }, []);
  return (
    <div className="page-wrapper-inner">
      <HeaderWrapper />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">Gallery Detail</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {galleryDetail?.length > 0 ? (
          <>
            <div className="row">
              <div className="offset-md-2 col-md-8 py-5">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-40">
                    <h2 className="title text-uppercase ">
                      {galleryDetail?.[0]?.gallery_title}
                      <span className="theme-color"></span>
                    </h2>
                    <div className="section-description">
                      <p className="mb-0">{galleryDetail?.[0]?.gallery_desc}</p>
                    </div>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {galleryDetail?.map((item, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-12 mb-4">
                  <div
                    className="gallery-image"
                    style={{
                      backgroundImage: `url(${item?.gallery_image})`,
                      height: "350px",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundPosition: "top center",
                      backgroundRepeat: "no-repeat",
                    }}
                    aria-label={item?.gallery_title}
                  />
                  {/* <img
                      src={item?.gallery_image}
                      alt={item?.gallery_title}
                      className="img-fluid"
                      style={{ height: "350px", width: "100%" }}
                    /> */}
                </div>
              ))}
            </div>{" "}
          </>
        ) : (
          <h3 className="title text-uppercase text-center py-5">
            No Detail Found
          </h3>
        )}
      </div>

      <FooterWrapper />
    </div>
  );
}

export default GalleryDetail;
