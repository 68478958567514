import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useSelector } from "react-redux";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";
import MeetDoctors from "../MeetDoctors";
import CeoMessage from "../CeoMessage";
import { Link } from "react-router-dom";
import SecondMeetDoctors from "../SecondMeetDoctors";
import SecondHomeReviews from "../Reviews/SecondHomeReviews";
import { SendPostRequest } from "../../http";
import { capitalize } from "@mui/material";

function AboutUs() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [hostpitalAbout, setHostpitalAbout] = useState({});

  const owlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  const features = [
    {
      icon: "fa-cutlery",
      title: "Crafted By Expert Chefs",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia deserunt mollit anim est laborum.",
    },
    {
      icon: "fa-map",
      title: "Do Not Miss Daily Menu",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia deserunt mollit anim est laborum.",
    },
    {
      icon: "fa-motorcycle",
      title: "Online Delivery",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia deserunt mollit anim est laborum.",
    },
    // {
    //   icon: 'fa-hourglass-half',
    //   title: 'Serving Meals In Minutes',
    //   description:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia deserunt mollit anim est laborum.',
    // },
  ];

  useEffect(() => {
    document.title = capitalize(sitedata?.site_title) + " :: About - ClinTa";
    window.scrollTo(0, 0);

    getHostpitalAboutData();
  }, []);

  const getHostpitalAboutData = async () => {
    const response = await SendPostRequest("content/aboutus");
    setHostpitalAbout(response.data);
  };

  return (
    <div className="page-wrapper-inner">
      <div className="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row text-left">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    <h1 className="page-title mb-0">About Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-6 col-md-8 col-12">
                <div className="title-wrap  margin-bottom-30">
                  <div className="section-title margin-bottom-25">
                    <h2 className="section-title mb-0 text-uppercase">
                      {hostpitalAbout?.who_we_are_heading}
                    </h2>
                    <span className="section-border-bottom"></span>
                  </div>
                  <div className="pad-top-15">
                    <p
                      className="margin-bottom-20 text-justify"
                      dangerouslySetInnerHTML={{
                        __html: hostpitalAbout?.who_we_are_description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-12">
                {/* <div
                className="about-vedio-frame"
                  dangerouslySetInnerHTML={{
                    __html: hostpitalAbout?.who_we_are_video?.replace(
                      /src="([^"]+)"/,
                      (match, p1) => `src="${p1}&autoplay=1"` 
                    ),
                  }}
                /> */}
                  <div
              className="about-vedio-frame"
              dangerouslySetInnerHTML={{
                __html: hostpitalAbout
                ?.who_we_are_video?.replace(
                  /src="([^"]+)"/,
                  (match, p1) => {
                    const newUrl = new URL(p1);
                    newUrl.searchParams.set("autoplay", "1");
                    newUrl.searchParams.set("rel", "0"); 
                    return `src="${newUrl.toString()}"`;
                  }
                ),
              }}
            />
              </div>
            </div>
          </div>
        </div>
        <div
          id="flip-box-section"
          className="flip-box-section pad-bottom-md-none index-1 relative semi-dark mt-5 mb-5 "
        >
          <div className="container">
            {/* Row */}
            <div className="row special-feature">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-40">
                    <h2 className="title text-uppercase mb-0">
                      {hostpitalAbout?.about_short_heading}
                      <span className="theme-color"></span>
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                  <div className="section-description">
                    {/* <p>{hostpitalAbout?.about_short_description}</p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hostpitalAbout?.about_short_description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              {hostpitalAbout?.principles?.map((data, index) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mb-3"
                  key={index}
                  data-animation="fadeInDown"
                >
                  <div className="s-feature-box text-center s-feature-box-mobile ">
                    <div className="mask-top">
                      {/* <i className={`fa ${data?.image} typo-dark`}></i> */}
                      <img
                        src={data?.image}
                        className=""
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                      <h4>{data?.heading}</h4>
                    </div>
                    <div className="mask-bottom">
                      {/* <i className={`fa ${data?.image} mb-1`}></i> */}
                      <img
                        src={data?.image}
                        className=""
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                      <h4 className="mb-2">{data?.heading}</h4>
                      <p
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <CeoMessage />

        {/* <MeetDoctors /> */}
        <SecondMeetDoctors />
        <section
          id="get-quote-section"
          className="get-quote-section bg-theme pad-top-40 pad-bottom-50 relative index-1 my-2"
        >
          <div className="container">
            <div className="row get-quote-3">
              <div className="col-lg-12">
                <div className="title-wrap mb-0">
                  <div className="section-title text-center mb-3">
                    <h3 className="title text-uppercase home-discount-offer">
                      50% off !! Come With OurOffer Coupons And Refer Friends!!
                    </h3>
                  </div>
                </div>
                <div className="button justify-content-center d-flex">
                  <Link
                    className="btn btn-border ms-3"
                    target="_blank"
                    title="Download Now"
                    to={"/appointment"}
                  >
                    {/* <i className="fa-solid fa-gift"></i> */}
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SecondHomeReviews />
        <FooterWrapper />
      </div>
    </div>
  );
}

export default AboutUs;
