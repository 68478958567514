import React from "react";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import SecondFooter from "./SecondFooter";
import StickyFooter from "./StickyFooter";
import ThirdFooter from "./ThirdFooter";
import { FaWhatsapp, FaRegCalendarCheck } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

function FooterWrapper() {
  const sitedata = useSelector((store) => store.global.loginData);
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith("/patient");

  return (
    <>
      {sitedata?.theme_footer === "smart_footer" ? (
        <Footer />
      ) : sitedata?.theme_footer === "versatile_footer" ? (
        <SecondFooter />
      ) : sitedata?.theme_footer === "sticky_footer" ? (
        <div style={{ marginBottom: "418px" }}>
          <StickyFooter />
        </div>
      ) : (
        <ThirdFooter />
      )}

      {!isDashboardRoute && (
        <div className="fixed-buttons">
          <button className="appointment-button">
            <FaRegCalendarCheck
              style={{ marginRight: "10px", fontSize: "17px" }}
            />
            <Link to={"/appointment"} className="appointment-link">
              Appointment
            </Link>
          </button>
          {sitedata?.whatsapp_chat?.trim() && (
            <button
              className="whatsapp-button-wrapper"
              onClick={() =>
                window.open(`https://wa.me/${sitedata.whatsapp_chat}`, "_blank")
              }
            >
              <FaWhatsapp style={{ marginRight: "10px", fontSize: "17px" }} />
              {sitedata.whatsapp_chat}
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default FooterWrapper;
