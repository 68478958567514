import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";
import { capitalize } from "@mui/material";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

function SecondHomeReviews() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [reviewsData, setReviewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Reviews - ClinTa";
    window.scrollTo(0, 0);
    getReviewsData();
  }, []);

  const getReviewsData = async () => {
    try {
      setIsLoading(true);
      const response = await SendPostRequest("reviews");
      setReviewsData(response?.data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleShowMore = (review) => {
    setSelectedReview(review);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReview(null);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        reviewsData?.length > 0 && (
          <div className="container pad-bottom-80 mt-5">
            <div className="row">
              <div class="offset-md-2 col-md-8">
                <div class="title-wrap text-center">
                  <div class="section-title margin-bottom-60">
                    <h2 class="section-title mb-0 text-uppercase">
                      Testimonials
                    </h2>
                    <span class="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 testimonails"
                data-animation="fadeInRight"
              >
                <OwlCarousel
                  className="owl-carousel testimonial-style-6 dark-switch"
                  loop={true}
                  nav={false}
                  dots={false}
                  autoplay={true}
                  autoplayHoverPause
                  autoplayTimeout={5000}
                  smartSpeed={250}
                  margin={30}
                  items={3}
                  responsive={{
                    0: { items: 1 },
                    768: { items: 2 },
                    1024: { items: 3 },
                  }}
                >
                  {reviewsData?.map((data, index) => (
                    // <div className="item" key={data?.id}>
                    //   <div
                    //     className="client-box text-left"
                    //     style={{ height: "170px" }}
                    //   >
                    //     <div className="client-image">
                    //       <img
                    //         className="img-circle img-thumbnail"
                    //         src={data?.profile_image}
                    //         style={{ width: "65px", height: "65px" }}
                    //         width="65"
                    //         height="65"
                    //       />
                    //     </div>
                    //     <p>
                    //       {truncateText(data?.detail, 15)}
                    //       {data?.detail?.split(" ").length > 15 && (
                    //         <Link
                    //           onClick={() => handleShowMore(data)}
                    //           className="fw-bold"
                    //         >
                    //           See More
                    //         </Link>
                    //       )}
                    //     </p>
                    //     <div className="client-details">
                    //       <strong className="theme-color mb-2">{data?.name}</strong>
                    //       <span>{data?.position}</span>
                    //     </div>
                    //   </div>
                    // </div>
                    <div class="item">
                      <div class="quote">
                        <blockquote class="small-text mb-0">
                          The standard chunk of Lorem Ipsum used since the 1500s
                          is reproduced below for those intereste. The standard
                          chunk of Lorem Ipsum used since the 1500s is
                          reproduced below for those intereste.
                        </blockquote>
                        <div class="star-rating text-right">
                          <i class="fa fa-star theme-color"></i>
                          <i class="fa fa-star theme-color"></i>
                          <i class="fa fa-star theme-color"></i>
                          <i class="fa fa-star theme-color"></i>
                          <i class="fa fa-star-half-o theme-color"></i>
                        </div>
                      </div>
                      <div class="client-details text-center">
                        <div class="client-image margin-bottom-10">
                          <img
                            class="img-circle b-radius-50"
                            src={data?.profile_image}
                            style={{ width: "65px", height: "65px" }}
                            alt=""
                          />
                        </div>
                        {/* <div class="client-details">
													<strong class="theme-color mb-2">John Doe</strong> 
													<span>Designer, zozothemes</span>
												</div> */}
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        )
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Review Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedReview && (
            <>
              <p>{selectedReview?.detail}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SecondHomeReviews;
