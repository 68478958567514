// Theme2Routes.js
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Theme2Components/Home";
// import "./Theme2Components/themeassets/css/style.module.css";

import "./Theme2Components/themeassets/css/bootstrap-icons.css";
// import "./Theme2Components/themeassets/css/bootstrap.min.css"
// import "./Theme2Components/themeassets/css/bootstrap.min.css.map"
// import "./Theme2Components/themeassets/css/bootstrap.rtl.css"
// import "./Theme2Components/themeassets/css/color_panel.css"
// import "./Theme2Components/themeassets/css/colors.css"
// import "./Theme2Components/themeassets/css/component.css"
// import "./Theme2Components/themeassets/css/flaticon.css"
// import "./Theme2Components/themeassets/css/flexslider.css"
// import "./Theme2Components/themeassets/css/font-awesome.min.css"
// import "./Theme2Components/themeassets/css/magnific-popup.css"
// import "./Theme2Components/themeassets/css/normalize.css"
// import "./Theme2Components/themeassets/css/owl.carousel.min.css"
// import "./Theme2Components/themeassets/css/owl.theme.default.min.css"
// import "./Theme2Components/themeassets/css/revolution-slider.css"
import "./Theme2Components/themeassets/css/simple-line-icons.min.css";
import "./Theme2Components/themeassets/css/themify-icons.css";
import "./Theme2Components/themeassets/css/animate.min.css";
import Login from "./Theme2Components/Login";
import SignUp from "./Theme2Components/SignUp";
import { useSelector } from "react-redux";
import LoginDashboard from "./Theme2Components/LoginDashboard";
import AboutContainer from "./Theme2Components/AboutContainer";
import DoctorListing from "./Theme2Components/Hospital/DoctorListing";
import DoctorDetail from "./Theme2Components/Hospital/DoctorDetail";
import BlogDetail from "./Theme2Components/Blogs/BlogDetail";
import BlogListing from "./Theme2Components/Blogs/BlogListing";
import NewsListing from "./Theme2Components/NewsUpdates/NewsListing";
import NewsDetail from "./Theme2Components/NewsUpdates/NewsDetail";
import AuthBlogDetail from "./Theme2Components/Blogs/AuthBlogDetail";
import Reviews from "./Theme2Components/Reviews/Reviews";
import ServicesListing from "./Theme2Components/Services/ServicesListing";
import ServicesDetail from "./Theme2Components/Services/ServicesDetail";
import FirstContactus from "./Theme2Components/ContactUS/FirstContactus";
import SecondContactus from "./Theme2Components/ContactUS/SecondContactus";
import DropCv from "./Theme2Components/ContactUS/DropCv";
import Feedback from "./Theme2Components/ContactUS/Feedback";
import Appointment from "./Theme2Components/ContactUS/Appointment";
import Gallery from "./Theme2Components/Gallery";
import HospitalClinic from "./Theme2Components/Doctor/HospitalClinic";
import PageNotFound from "./Theme2Components/PageNotFound";
import FacilitiesListing from "./Theme2Components/HomeFacilities/FacilitiesListing";
import FacilitiesDetail from "./Theme2Components/HomeFacilities/FacilitiesDetail";
import CareerListing from "./Theme2Components/Hospital/CareerListing";
import GalleryDetail from "./Theme2Components/GalleryDetail";
import { Circles } from "react-loader-spinner";

function Theme2Routes({ isData, setIsdata, patientLogin }) {
  // const patientLogin = useSelector((state) => state.patient.patientLogin);
  const sitedata = useSelector((store) => store.global.loginData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading or delay (e.g., waiting for font, data, or external assets)
    setTimeout(() => {
      setLoading(false); // Set loading to false once loading is complete
    }, 3000); // 3 seconds delay
  }, []);

  import("./Theme2Components/themeassets/css/style.css").then(() => {});
  //   import("./Theme2Components/themeassets/css/component.css").then(() => {});
  import("./Theme2Components/themeassets/css/color-schemes/red.css").then(
    () => {}
  );

  const [faviconUrl, setFaviconUrl] = useState("");
  useEffect(() => {
    if (sitedata && sitedata?.site_logo) {
      const base64Url = `${sitedata?.site_logo}`;
      setFaviconUrl(base64Url);
    }
  }, [sitedata]);

  useEffect(() => {
    if (faviconUrl) {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.href = faviconUrl;
      } else {
        const newFaviconLink = document.createElement("link");
        newFaviconLink.rel = "icon";
        newFaviconLink.href = faviconUrl;
        document.head.appendChild(newFaviconLink);
      }
    }
  }, [faviconUrl]);

  const primaryColor = sitedata?.options?.theme_primary_color || "#ffc400";
  const secondaryColor = sitedata?.options?.theme_secondary_color || "#fff";
  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
  }, [primaryColor]);

  const fontFamily = sitedata?.paragraph_font || "Poppins, sans-serif";
  const fontFamilyBanner = sitedata?.header_font || "Arimo, sans-serif";

  useEffect(() => {
    // Function to load a font
    const loadFont = (font, variableName) => {
      if (font) {
        const primaryFont = font.split(",")[0].trim();
        const link = document.createElement("link");
        link.href = `https://fonts.googleapis.com/css2?family=${primaryFont.replace(
          " ",
          "+"
        )}:wght@400;700&display=swap`;
        link.rel = "stylesheet";
        document.head.appendChild(link);
        document.documentElement.style.setProperty(variableName, font);
      }
    };

    // Load paragraph font
    loadFont(fontFamily, "--paragraph-font");

    // Load header font
    loadFont(fontFamilyBanner, "--header-font");
  }, [fontFamily, fontFamilyBanner]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Circles
          height="80"
          width="80"
          color="#f5c800"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<Home isData={isData} setIsdata={setIsdata} />}
      />
      <Route path="/doctors" element={<DoctorListing />} />
      <Route path="/doctor/:id/:slug" element={<DoctorDetail />} />
      <Route path="/about" element={<AboutContainer />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/contact" element={<FirstContactus />} />
      {/* <Route path="/contact" element={<SecondContactus />} /> */}
      <Route path="/careers" element={<CareerListing />} />
      <Route path="/blogs" element={<BlogListing />} />
      <Route path="/blog/detail/:slug" element={<BlogDetail />} />
      <Route path="/author/detail/:slug" element={<AuthBlogDetail />} />
      <Route path="/news" element={<NewsListing />} />
      <Route path="/news/detail/:slug" element={<NewsDetail />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/specialities" element={<ServicesListing />} />
      <Route path="/speciality/:id/:slug" element={<ServicesDetail />} />
      <Route path="/facility/:slug" element={<FacilitiesDetail />} />
      <Route path="/facilities" element={<FacilitiesListing />} />
      <Route path="/drop-cv" element={<DropCv />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/gallery/:id" element={<GalleryDetail />} />
      <Route path="/hospital-clinic/:slug" element={<HospitalClinic />} />
      <Route path="/not-found" element={<PageNotFound />} />

      {patientLogin ? (
        <Route path="patient/*" element={<LoginDashboard />} />
      ) : (
        <Route path="patient/*" element={<Navigate to="/" />} />
      )}
    </Routes>
  );
}

export default Theme2Routes;
