import React from "react";
import ReactPlayer from "react-player";

const VideoBgSection = () => {
  return (
    <div id="video-bg-section" className="video-bg-section my-video">
      {/* <iframe
    width="100%"
    height="500"
    src="https://www.youtube.com/embed/Gt4vZ_ITpTw"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe> */}
      <div className="row">
        <div className="col-lg-12">
          <div id="vbg2">
            <ReactPlayer
              // url="https://youtu.be/Gt4vZ_ITpTw"
              url="https://youtu.be/Clui8RP3Ed4"
              playing={true}
              controls={true}
              width="100%"
              height="500px"
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    start: 10,
                    mute: 1,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBgSection;
